<template>
  <div class="mt-5">
    <AlertListWidget></AlertListWidget>
  </div>
</template>

<script>
import AlertListWidget from "../../content/custom_components/AlertListWidget.vue";
export default {
  components: {
    AlertListWidget
  }
};
</script>

<style></style>
