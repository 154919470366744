<template>
  <div class="card card-custom gutter-b" id="incident-record">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark"
          >Incident Alert</span
        >
      </h3>

      <div class="card-toolbar">
        <LineIncidentDropdown
          cus-id="c854dba0-b56c-11e8-b6e5-f9ed135a2fc1"
        ></LineIncidentDropdown>
        <button-expand screen-id="incident-record"></button-expand>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <!--begin::Table-->
    <b-card-body>
      <b-row class="mb-3">
        <b-col cols="3" class="d-flex align-items-end">
          <b-form-select
            size="sm"
            v-model="paging.perPage"
            :options="[10, 25, 50, 100]"
          >
          </b-form-select>
        </b-col>
        <b-col offset="4" cols="5">
          <div class="d-flex justify-content-end">
            <div class="mt-2 d-flex flex-row align-items-center">
              <label for="datepicker">
                <span class="mr-1 font-weight-bold">Date:</span>
              </label>

              <b-form-datepicker
                dropleft
                reset-button
                size="sm"
                id="datepicker"
                v-model="filter.created_at"
                class="mb-2"
                locale="en"
                :max="todayDate"
              ></b-form-datepicker>
            </div>
          </div>
          <b-form inline class="justify-content-end">
            <div class="d-flex flex-column">
              <div class="d-flex flex-row">
                <div class="d-flex flex-column flex-sm-row">
                  <label for="status" class="mr-1 font-weight-bold"
                    >Status:
                  </label>
                  <b-form-select
                    id="status"
                    class="mr-1"
                    :options="[
                      {
                        value: null,
                        text: 'All'
                      },
                      { value: 'handle', text: 'Handle' },
                      { value: 'not handle', text: 'Not Handle' },
                      { value: 'unread', text: 'Unread' }
                    ]"
                    size="sm"
                    v-model="filter.recordStatus"
                  ></b-form-select>
                </div>

                <div class="d-flex flex-column flex-sm-row">
                  <label for="source" class="mr-1 font-weight-bold"
                    >Source:
                  </label>
                  <b-form-select
                    id="source"
                    class="mr-1"
                    :options="[
                      { value: null, text: 'All' },
                      { value: 'facebook', text: 'facebook' },
                      { value: 'twitter', text: 'twitter' },
                      { value: 'pantip', text: 'pantip' },
                      { value: 'youtube', text: 'youtube' },
                      { value: 'website', text: 'website' },
                      { value: 'instagram', text: 'instagram' },
                      { value: 'tiktok', text: 'tiktok' }
                    ]"
                    size="sm"
                    v-model="filter.source"
                  ></b-form-select>
                </div>
              </div>

              <b-form-input
                class="mt-2"
                size="sm"
                type="text"
                placeholder="Search"
                v-model="filter.q"
              ></b-form-input>
            </div>
          </b-form>
        </b-col>
      </b-row>

      <b-table
        style="min-height:600px"
        sticky-header
        bordered
        responsive
        :items="incidentLogDatas"
        :fields="incidentLogFields"
        show-empty
        :busy="!isIncidentLogLoaded"
      >
        <template v-slot:table-busy>
          <div class="text-danger my-2">
            <b-spinner class="align-middle mx-2"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template v-slot:head(created_at)="data">
          <div style="width:120px">{{ data.label }}</div>
        </template>

        <template v-slot:head(updated_at)="data">
          <div style="width:120px">{{ data.label }}</div>
        </template>
        <template v-slot:empty>
          <center>No data</center>
        </template>

        <template v-slot:cell(no)="row">
          {{ iterationLink + row.index + 1 }}
        </template>

        <template v-slot:cell(detail)="row">
          <div style="min-width:300px">
            <div class="d-flex flex-row justify-content-between">
              <span class="h4">[{{ row.item.brand.name }}]</span>
              <span
                v-if="row.item.personalPost"
                class="text-warning font-weight-bold text-sm"
                >[ Personal Post ]</span
              >
            </div>
            <div v-html="row.item.text" style="max-width:360px"></div>
            <!-- <b-form-textarea
              class="w-100"
              type="text"
              size="lg"
              :value="decodeHTML(row.item.text)"
              readonly
              rows="5"
            ></b-form-textarea> -->
          </div>
        </template>

        <template v-slot:cell(remark)="row">
          <div v-html="row.item.remark"></div>
        </template>

        <template v-slot:cell(link)="row">
          <div>
            <a :href="row.item.link" target="_blank">
              <i class="flaticon2-graphic-design text-info"></i>
            </a>
          </div>
        </template>

        <template v-slot:cell(remarkMessage)="row">
          <textarea
            rows="5"
            style="min-width:150px"
            class="form-control"
            v-model="row.item.remarkMessage"
            @change="onHandle(row.item, { remarkMessage: $event.target.value })"
          ></textarea>
        </template>

        <template v-slot:cell(actions)="row">
          <b-form-radio-group
            style="min-width:120px"
            :id="`radio-group-${row.index}`"
            v-model="row.item.recordStatus"
            :options="[
              { value: 'handle', text: 'Handle' },
              { value: 'not handle', text: 'Not Handle' }
            ]"
            :name="`recordStatus${[row.index]}`"
            @change="onHandle(row.item, { recordStatus: $event })"
          ></b-form-radio-group>

          <!-- <b-form-checkbox
            size="md"
            v-model="row.item.recordStatus"
            value="handle"
            unchecked-value="not handle"
            @change="onHandle(row.item, $event)"
          >
            Handle
          </b-form-checkbox> -->
        </template>
      </b-table>

      <div class="d-flex flex-row justify-content-between align-items-center">
        <span v-if="paging"
          >{{ paging.totalRows | number_format }} records</span
        >
        <b-pagination
          v-if="paging.numberOfPages"
          v-model="paging.currentPage"
          :total-rows="paging.totalRows"
          align="right"
          :number-of-pages="paging.numberOfPages"
          :per-page="paging.perPage"
          use-router
          @change="changeIncidentLog"
        ></b-pagination>
      </div>
    </b-card-body>
    <!--end::Table-->

    <b-toast id="new-incident" variant="info" solid no-auto-hide>
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <b-img
            blank
            blank-color="#ff5555"
            class="mr-2"
            width="12"
            height="12"
          ></b-img>
          <strong class="mr-auto">Notice!</strong>
        </div>
      </template>
      <div>{{ diffIncident }} incident(s) arrived.</div>
      <b-button class="mt-2" size="sm" @click="onReloadIncident(1)"
        >Reload</b-button
      >
    </b-toast>
  </div>
</template>

<script>
import HtmlEntities from "../../../core/plugins/html-entities";
import moment from "moment";
import IncidentLogService from "../../../core/services/incident.service";
import _ from "lodash";
import { LOGOUT } from "../../../core/services/store/auth.module";
import LineIncidentDropdown from "./LineIncidentDropdown.vue";
import ButtonExpand from "./ButtonExpand.vue";
import config from "../../../core/config/config";
import SockJS from "sockjs-client";

export default {
  name: "AlertListWidget",
  components: {
    LineIncidentDropdown,
    ButtonExpand
  },
  data() {
    return {
      connection: null,
      hasNewIncident: false,
      filter: { q: null, source: null, recordStatus: null, created_at: null },
      incidentLogFields: [
        {
          key: "no",
          label: "#",
          class: "text-center"
        },
        { key: "publish_date", label: "Publish date" },

        {
          key: "detail",
          label: "Detail"
        },
        {
          key: "link",
          label: "Link"
        },
        { key: "remark", label: "Remark" },
        {
          key: "status",
          label: "Status"
        },
        { key: "created_at", label: "Created at" },
        { key: "remarkMessage", label: "Remark message" },
        {
          key: "actions",
          label: "Action",
          class: "text-left",
          width: 300
        }
      ],
      incidentLogDatas: [],
      paging: {
        perPage: 10,
        currentPage: 1,
        totalRows: 0,
        numberOfPages: 0
      },
      isIncidentLogLoaded: true,
      lastIncidentTotal: 0,
      pullingID: null,
      diffIncident: 0,
      isLastIncidentLoaded: false
    };
  },

  created() {
    this.connectWebsocket();
  },
  computed: {
    iterationLink() {
      return this.paging.perPage * (this.paging.currentPage - 1);
    },
    todayDate() {
      return moment().format("YYYY-MM-DD");
    }
  },
  mounted() {
    this.getIncidentLogs().then(() => {
      this.getLastIncidentLogs();
      this.pullingID = setInterval(() => {
        this.getLastIncidentLogs();
      }, 5000);
    });
  },
  destroyed() {
    clearInterval(this.pullingID);
  },
  watch: {
    filter: {
      handler: _.debounce(function() {
        this.paging.currentPage = 1;
        this.getIncidentLogs();
      }, 500),
      deep: true
    },
    "paging.perPage"() {
      this.paging.currentPage = 1;
      this.getIncidentLogs();
    }
  },
  methods: {
    connectWebsocket() {
      console.log("Starting connection to WebSocket Server");
      this.connection = new SockJS(config.sockjs);

      this.connection.onmessage = event => {
        try {
          let data = JSON.parse(event.data);
          this.onReceiveUpdate(data);
        } catch (e) {
          return;
        }
      };

      this.connection.onopen = function() {
        // console.log(event);
        console.log("Successfully connected to the echo websocket server...");
      };

      this.connection.onclose = () => {
        console.log("WebSocket close");
        this.connection = null;
        setTimeout(() => {
          this.connectWebsocket();
        }, 1000);
      };
    },
    onReceiveUpdate(record) {
      this.incidentLogDatas = this.incidentLogDatas.map(v => {
        if (record.logId === v.logId) {
          return {
            ...v,
            ...record
          };
        } else {
          return v;
        }
      });
    },
    sendMessage(message) {
      // console.log(this.connection);
      this.connection.send(message);
    },

    onHandle(item, recordChange) {
      return IncidentLogService.update(item.logId, {
        ...recordChange
      })
        .then(() => {
          this.sendMessage(
            JSON.stringify({
              logId: item.logId,
              ...recordChange
            })
          );
          if (recordChange.recordStatus) {
            this.$root.$bvToast.toast(`Mark as ${recordChange.recordStatus}`, {
              title: `Success`,
              variant: "success",
              solid: true
            });
          }
          if (recordChange.remarkMessage) {
            this.$root.$bvToast.toast(
              `Update remark message to '${recordChange.remarkMessage}'`,
              {
                title: `Success`,
                variant: "success",
                solid: true
              }
            );
          }
        })
        .catch(() => {
          this.$root.$bvToast.toast("Error", {
            title: `Error`,
            variant: "danger",
            solid: true
          });
        });
    },
    onReloadIncident(page) {
      this.paging.currentPage = page;
      this.getIncidentLogs().then(() => {
        this.lastIncidentTotal = this.paging.totalRows;
        this.$root.$bvToast.hide("new-incident");
      });
    },
    changeIncidentLog(page) {
      this.paging.currentPage = page;
      this.getIncidentLogs();
    },
    decodeHTML(str) {
      return HtmlEntities.decode(str);
    },
    getIncidentLogs() {
      this.isIncidentLogLoaded = true;

      //  api result here
      return IncidentLogService.get({
        offset: this.paging.perPage * (this.paging.currentPage - 1),
        limit: this.paging.perPage,
        ...this.filter
      })
        .then(({ data }) => {
          let incidentLogs = data.data;

          this.paging.totalRows = incidentLogs.total;
          this.paging.numberOfPages = Math.ceil(
            this.paging.totalRows / this.paging.perPage
          );

          let incidentLogDatas = incidentLogs.results || [];
          this.incidentLogDatas = incidentLogDatas.map(v => {
            return {
              ...v,
              remark: v.remark ? v.remark.replace(/\n/g, "<br/>") : v.remark,
              created_at: moment(v.created_at).format("DD/MM/YYYY HH:mm"),
              updated_at: moment(v.updated_at).format("DD/MM/YYYY HH:mm"),
              publish_date: moment(v.date).format("DD/MM/YYYY HH:mm")
            };
          });

          this.isIncidentLogLoaded = true;
        })
        .catch(error => {
          console.error(error);
          if (error.response && error.response.status === 401) {
            this.$store.dispatch(LOGOUT).then(() => {
              this.$router.push({ name: "login" });
            });
          }
          throw error;
        })
        .finally(() => {
          this.isIncidentLogLoaded = true;
        });
    },

    getLastIncidentLogs() {
      return IncidentLogService.get({
        offset: 0,
        limit: 1
      })
        .then(({ data }) => {
          let incidentLogs = data.data;
          let lastIncidentTotal = incidentLogs.total;

          if (!this.isLastIncidentLoaded) {
            this.lastIncidentTotal = lastIncidentTotal;
            this.isLastIncidentLoaded = true;
          } else {
            if (
              lastIncidentTotal &&
              this.lastIncidentTotal &&
              lastIncidentTotal > this.lastIncidentTotal
            ) {
              this.diffIncident = lastIncidentTotal - this.lastIncidentTotal;
              // this.lastIncidentTotal = lastIncidentTotal;

              this.$root.$bvToast.show("new-incident");
            } else {
              this.hasNewIncident = false;
              this.lastIncidentTotal = lastIncidentTotal;
            }
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 401) {
            this.$store.dispatch(LOGOUT).then(() => {
              this.$router.push({ name: "login" });
            });
          }
        });
    }
  }
};
</script>

<style></style>
