import axios from "axios";
import DOMService from "./dom.service";
import config from "../config/config";

const IncidentLogService = {
  get(data) {
    return axios.get("/kbank", {
      baseURL: config.api_url,
      params: data,
      headers: {
        Token: DOMService.getToken()
      }
    });
  },
  update(logId, data) {
    return axios.put(`/kbank/${logId}`, data, {
      baseURL: config.api_url,
      headers: { Token: DOMService.getToken() }
    });
  },
  async downloadRangeEngagement({
    cusId,
    competitorId,
    overallFromDate,
    overallToDate
    // weekFromDate,
    // weekToDate
  }) {
    let response = null;
    try {
      response = await axios.get("/kbank/export", {
        baseURL: config.api_url,
        headers: { Token: DOMService.getToken() },
        responseType: "blob",
        params: {
          cusId,
          competitorId,
          overallFromDate,
          overallToDate
          // weekFromDate,
          // weekToDate
        }
      });
      let filename = response.headers.filename;
      response = { blob: new Blob([response.data]), filename };
    } catch (error) {
      response = {
        error: error.response.data
      };
    }
    return response;
  }
};
export default IncidentLogService;
