<template>
  <div>
    <b-dropdown
      size="sm"
      variant="button"
      toggle-class="custom-v-dropdown btn btn-light btn-sm btn-icon"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <i class="ki ki-bold-more-hor text-primary"></i>
      </template>
      <div class="navi navi-hover min-w-md-250px">
        <b-dropdown-text tag="div" class="navi-item">
          <a
            href="#"
            class="navi-link"
            @click.prevent
            v-b-modal.export-range-line-incident-engagement
          >
            <span class="navi-icon">
              <i class="flaticon2-file-2"></i>
            </span>
            <span class="navi-text">Export Engagement</span>
          </a>
        </b-dropdown-text>
      </div>
    </b-dropdown>

    <b-modal
      id="export-range-line-incident-engagement"
      ref="modal"
      title="Export engagement by date range"
      @ok="downloadRangeEngagementIncident"
      ok-variant="primary"
      ok-title="Export"
      ok-only
    >
      <div class="mb-3">
        <!-- <label for="week_date_range">Week date range</label>
        <el-date-picker
          :editable="false"
          :clearable="false"
          class="w-100"
          v-model="weekDateRange"
          type="daterange"
          align="left"
          size="small"
          start-placeholder="Start Date"
          end-placeholder="End Date"
          format="dd/MM/yyyy"
          value-format="timestamp"
        >
        </el-date-picker> -->
      </div>
      <div>
        <label for="overall_date_range">Date range</label>
        <el-date-picker
          class="w-100"
          v-model="overallDateRange"
          type="datetimerange"
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
          size="small"
          value-format="timestamp"
          :picker-options="pickerOptions"
          align="left"
          format="dd/MM/yyyy HH:mm"
          :editable="false"
          :clearable="false"
        >
        </el-date-picker>
        <!-- <el-date-picker
          :editable="false"
          :clearable="false"
          class="w-100"
          v-model="overallDateRange"
          type="datetimerange"
          range-separator="To"
          align="left"
          size="small"
          start-placeholder="Start Date"
          end-placeholder="End Date"
          format="dd/MM/yyyy"
          value-format="timestamp"
          :picker-options="pickerOptions"
        >
        </el-date-picker> -->
      </div>
    </b-modal>
  </div>
</template>

<script>
import FileSaver from "file-saver";
import moment from "moment";
import IncidentLogService from "../../../core/services/incident.service";
export default {
  name: "LineIncidentDropdown",
  props: {
    cusId: String,
    competitorId: String
  },
  data() {
    return {
      pickerOptions: {
        // limit date
        disabledDate: this.disableDateFunction
      },
      overallDateRange: [
        moment()
          .startOf("isoWeek")
          .startOf("days")
          .valueOf(),
        moment()
          // .add(1, "days")
          .endOf("days")
          .valueOf()
      ]
      // weekDateRange: [
      //   moment()
      //     .startOf("isoWeek")
      //     .startOf("days")
      //     .valueOf(),
      //   moment()
      //     .endOf("isoWeek")
      //     // .add(1, "days")
      //     .startOf("days")
      //     .valueOf()
      // ]
    };
  },
  methods: {
    disableDateFunction(date) {
      return (
        moment(date).isBefore(
          moment()
            .subtract(1, "month")
            .startOf("month")
        ) || moment(date).isAfter(moment())
      );
    },
    async downloadRangeEngagementIncident(e) {
      e.preventDefault();
      let blob = await IncidentLogService.downloadRangeEngagement({
        cusId: this.cusId,
        competitorId: this.competitorId,
        overallFromDate: moment(this.overallDateRange[0]).valueOf(),
        overallToDate: moment(this.overallDateRange[1]).valueOf()
        // weekFromDate: moment(this.weekDateRange[0])
        //   .startOf("day")
        //   .valueOf(),
        // weekToDate: moment(this.weekDateRange[1])
        //   .endOf("day")
        //   .valueOf()
      });
      FileSaver.saveAs(blob.blob, decodeURIComponent(blob.filename));
    }
  }
};
</script>

<style></style>
